import { createStore } from "vuex";
import { store } from "@sutochno/header/index.js";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: store
  }
});
