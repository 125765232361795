import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import i18n from "./i18n";
import bootstrap from './bootstrap'
import globalProvides from "./globalProvides";
import VueGtag from "vue-gtag";
import { ToasterPlugin } from "@sutochno/uikit";

const app = createApp(App)

app.config.productionTip = false;
app.config.ignoredElements = ['header-tag']

const isProd = process.env.NODE_ENV === "production";
const ID = "#header-vuejs";

app.use(store)
app.use(i18n)
app.use(ToasterPlugin)

app.use(
    VueGtag,
    {
        enabled: isProd,
        customPreconnectOrigin: null,
        config: {
            id: "G-B0K4L0V6J8",
        },
    }
);

bootstrap(app);
globalProvides(app);

app.mount(ID);
