<template>
  <header-tag 
    id="header-vuejs"
    :data-location-id="location && location.id ? location.id : null"
    :data-location-type="location && location.type ? location.type : null"
    :data-location-title="location && location.title ? location.title : null"
    :data-page-type="pageType ? pageType : null"
  >
    <Header :location="location" :pageType="pageType" :app="false"></Header>
    <Toaster />
  </header-tag>
</template>
<script>
//import Header from "./Header/src/Header.vue";
import { Header } from "@sutochno/header/index.js";


export default {
  name: "App",
  inject: ['location', 'pageType'],
  components: {
    Header
  }
};
</script>
<style lang="scss">
body {
  margin: 0;
}
// #header-vuejs
header-tag {
  font-family: "PT Sans", sans-serif;
  // font-size: 15px;

  * {
    box-sizing: border-box;
  }

  // @import "node_modules/bootstrap/scss/_functions.scss";
  // @import "node_modules/bootstrap/scss/_variables.scss";
  // @import "node_modules/bootstrap/scss/_mixins.scss";
  // @import "node_modules/bootstrap/scss/_buttons.scss";
  // @import "bootstrap-vue/src/components/form-input/index.scss";
  // @import "node_modules/bootstrap-vue/src/components/form-input/index";

  @import "assets/style/env.scss";
  
}
</style>