const elemID = document.getElementById("header-vuejs");
const location = [
    'data-location-id',
    'data-location-type',
    'data-location-url',
    'data-location-title'
].reduce((acc, key) => {
    const value = elemID.getAttribute(key);
    if(value && !acc) acc = {}
    if(value) acc[key.replace("data-location-", "")] = value;
    return acc;
}, null)

const pageType = elemID?.getAttribute('data-page-type') || null;

export default (app) => {
    app.provide("location", location);
    app.provide("pageType", pageType);
};